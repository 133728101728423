@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

.bg-custom {
  background: radial-gradient(41.64% 155.67% at 50% 50%, #161821 0%, #050505 100%);
}


#download:after, #start:after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -11px;
  top: 0px;
  width: 0;
  height: 0;
  border-left: 22px solid transparent;
  border-right: 22px solid transparent;
  border-top: 22px solid #FFF;
  clear: both;
}

.bgt {
  background: url('./assets/bgt.png') center repeat #000;
}

.bgc {
  background: radial-gradient(41.64% 155.67% at 50% 50%, #161821 0%, #050505 100%);
}

.animation-pulse {
  /*
  transform: scale(0.8);*/
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
      transform: scale(0.8);
  }

  50% {
      transform: scale(1);
  }

  100% {
      transform: scale(0.8);
  }
}
